import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UnitTitlePlugin, { TranslationsLoader } from '@edx/unit-title-plugin';
import { DatadogLoggingService } from '@edx/frontend-logging';
import {
  LockPaywall,
  UpgradeNotification,
  NextUnitButton,
  SectionList,
} from '@edx/frontend-plugin-advertisements';
import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import OutlinePlugin from '@edx/left-sidebar-navigation-plugin';
import CourseOutlineSidebarTriggerPlugin from '@edx/course-outline-sidebar-trigger-plugin';
import hideCourseSuggestionsForEnterpriseLearners from '@edx/course-end-recommendations-plugin';
import NotificationsTray from '@edx/frontend-plugin-notifications';

const modifyProgressCertificateWidget = (widget) => {
  const { RenderWidget } = widget;
  if (RenderWidget.props.id.includes('upgrade')) {
    widget.RenderWidget = UpgradeNotification({ id: 'progress_certificate_status_slot' });
  }
  
  return widget;
;}

// Load environment variables from .env file
const config = {
  ...process.env,
  AI_TRANSLATIONS_URL: 'https://ai-translations.stage.edx.org',
  loggingService: DatadogLoggingService,
  OPTIMIZELY_FULL_STACK_SDK_KEY: 'GiXcfTudkzWv8T29KJgES',
  pluginSlots: {
    unit_title_slot: {
      keepDefault: false,
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'unit_title_widget',
            type: DIRECT_PLUGIN,
            RenderWidget: (props) => {
              const dispatch = useDispatch();
              return <UnitTitlePlugin {...props} dispatch={dispatch} />;
            },
          },
        },
      ],
    },
    sequence_container_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: "sequence_container_widget",
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: () => <div id="whole-course-translation-feedback-widget" />,
          },
        },
      ],
    },
    gated_unit_content_message_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'gated_unit_content_message_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: LockPaywall,
          },
        },
      ],
    },
    notification_widget_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_widget_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    notification_tray_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_tray_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    outline_tab_notifications_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'outline_tab_notifications_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    next_unit_top_nav_trigger_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'next_unit_top_nav_trigger_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: NextUnitButton,
          },
        },
      ],
    },
    content_iframe_loader_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: "content_iframe_loader_slot",
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: TranslationsLoader,
          },
        },
      ],
    },
    progress_certificate_status_slot: {
      keepDefault: true,
      plugins: [{
        op: PLUGIN_OPERATIONS.Modify,
        widgetId: 'default_contents',
        fn: modifyProgressCertificateWidget,
      }]
    },
    course_outline_sidebar_slot: {
      keepDefault: false,
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'custom_sidebar_component',
            type: DIRECT_PLUGIN,
            RenderWidget: OutlinePlugin,
          },
        },
      ]
    },
    course_outline_sidebar_trigger_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'course_outline_sidebar_trigger_slot',
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: (props) => {
              return (
                <CourseOutlineSidebarTriggerPlugin Link={Link} {...props} />
              );
            },
          },
        },
      ]
    },
    notifications_tray: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
        id: 'notification_tray',
            type: DIRECT_PLUGIN,
            RenderWidget: NotificationsTray,
          },
        }
      ]
    },
    course_recommendations_slot: {
      keepDefault: true,
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Modify,
          widgetId: 'default_contents',
          fn: hideCourseSuggestionsForEnterpriseLearners,
        },
      ]
    },
    course_home_section_outline_slot: {
      keepDefault: false,
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'custom_section_outline_component',
            type: DIRECT_PLUGIN,
            RenderWidget: SectionList,
          },
        },
      ]
    },
  },
};

export default config;
